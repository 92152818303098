<template>
    <v-tab-item class="mt-4">
        <v-row>
            <v-col sm='6'>
                <v-row v-if="!selectedUser._id" >
                    <v-col sm="12">
                        <div class="label">
                            <label>Seleziona un cliente</label>
                        </div>
                        <v-autocomplete label="Cerca per nome, cognome o email o telefono"
                                        :search-input.sync="searchUser"
                                        :loading="loadingSearchUser"
                                        :items="listUsers"
                                        item-text="rNameSearchUser"
                                        hide-no-data
                                        return-object
                                        no-filter
                                        prepend-icon="mdi-magnify"
                                        v-model="selectedUser"
                                        @change="getUserBike()" />
                    </v-col>
                </v-row>
                <v-row v-if="selectedUser && selectedUser._id">
                    <v-col sm='12'>
                        <v-card elevation="3">
                            <v-card-title class="pb-0">Cliente</v-card-title>
                            <v-card-text>
                                <div class="my-2 text-subtitle-1">
                                    <b>{{ selectedUser.nome }} {{ selectedUser.cognome }}</b>
                                </div>
                                <div>
                                    <span v-if="selectedUser.email">
                                        <i class="fas fa-envelope mr-1" style="font-size: 15px !important;"></i>
                                        {{ selectedUser.email }}
                                        <br>
                                    </span>
                                    <span v-if="selectedUser.telefono">
                                        <i class="fas fa-phone-square mr-1" style="font-size: 15px !important;"></i>
                                        {{ selectedUser.telefono }}
                                        <br>
                                    </span>
                                    <span>
                                         <i class="fas fa-address-card mr-1" style="font-size: 15px !important;"></i>
                                        {{ puntiTessera }}/10
                                    </span>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn icon class="mr-3" @click="selectedUser = {}; getUserBike()">
                                    <i class="fas fa-trash"></i>
                                </v-btn>
                                <v-btn icon @click="editUser">
                                    <i class="fas fa-edit"></i>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="selectedUser && selectedUser._id">
                    <v-col sm="12">
                        <v-card elevation="3">
                            <v-card-title class="pb-0">Lavorazioni</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-checkbox v-model="lavaggio" color="primary" label="Lavaggio" />
                                    </v-col>
                                    <v-col>
                                        <v-checkbox v-model="primo_tagliando" color="primary" label="Primo tagliando" />
                                    </v-col>
                                    <v-col>
                                        <v-checkbox v-model="garanzia" color="primary" label="Garanzia" />
                                    </v-col>
                                    <v-col v-if="garanzia" sm="12">
                                        <v-select v-model="fornitore_garanzia"
                                                  label="Fornitore garanzia"
                                                  :items="brands"
                                                  item-text="rName"
                                                  item-value="_id"
                                                  style="width: 100%;"/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <hr>
                                    </v-col>
                                </v-row>
                                <v-row v-if="spuntePrese && spuntePrese.length">
                                    <v-col v-for="spunta of spuntePrese"
                                           :key="spunta._id"
                                           sm="4"
                                    >
                                        <v-checkbox v-model="selectedSpuntePrese" :label="spunta.rName" :value="spunta._id"/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox v-model="telefonare_quando_pronta" color="primary" label="Telefonare quando pronta" />
                                    </v-col>
                                </v-row>
                                <v-row class="mt-2">
                                    <v-col sm="12">
                                        <label>Inconvenienti e richieste</label>
                                        <v-textarea v-model="richieste" solo name="input-7-4"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm='6'>
                <v-row v-if="!selectedUser._id">
                    <v-col sm='12'>
                        <AggiungiCliente @closeModalAddUser="selectNewUser"/>
                    </v-col>
                </v-row>
                <v-row v-if="selectedUser && selectedUser._id">
                    <v-col v-if="!showAddBike" sm='12'>
                        <label v-if="listBikes.length > 0">Seleziona una bici</label>
                        <v-simple-table v-if="listBikes.length > 0" class="client-table mb-5">
                            <thead>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-left">Modello</th>
                                <th class="text-left">Marca</th>
                                <th class="text-left">Tipo</th>
                                <th class="text-left">Dealer</th>
                                <th class="text-left">Data acquisto</th>
                                <th class="text-left">N. Telaio</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="bike in listBikes"
                                :key="bike._id"
                                :class="selectedBike._id === bike._id ? 'active': ''"
                                @click="selectBike(bike)"
                            >
                                <td>
                                    <v-checkbox v-model="bike.active" color="primary" />
                                </td>
                                <td>{{ bike.modello }}</td>
                                <td>{{ bike.rMarca }}</td>
                                <td>
                                    <span v-if="bike.tipo === 'strada'">Strada</span>
                                    <span v-else-if="bike.tipo === 'mtb'">Mtb</span>
                                    <span v-else-if="bike.tipo === 'city'">City</span>
                                    <span v-else-if="bike.tipo === 'gravel'">Gravel</span>
                                    <span v-if="bike.ebike"> <i style="font-size: 18px !important;padding-left: 5px;" class="fas fa-plug"/></span>
                                </td>
                                <td>
                                    <span v-if="bike.dealer && !bike.dealer.ciclimattio">{{ bike.dealer.nome }}</span>
                                    <span v-if="bike.dealer && bike.dealer.ciclimattio">Ciclimattio</span>
                                </td>
                                <td>{{ controlDate(bike.data_acquisto) }}</td>
                                <td>{{ bike.numero_telaio}}</td>
                                <td @click="deleteBike(bike)">
                                    <i class="fas fa-trash"></i>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                        <span class="font-weight-bold" style="float: right" @click="showAddBike = true">
                            <v-icon>mdi-plus-circle</v-icon>
                            Nuova bici
                        </span>
                    </v-col>
                    <v-col v-if="showAddBike" sm='12'>
                        <AggiungiBici :idUser="selectedUser._id" @closeModalBike="getUserBike"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-tab-item>
</template>
<script>
import { momentLL } from "@/components/js/formatDate";
import apiUser from '@/components/Presa/cliente/js/user';
import AggiungiCliente from '@/components/Presa/cliente/AggiungiCliente';
import AggiungiBici from '@/components/Presa/cliente/AggiungiBici';
import { generalFunction } from "@/components/js/generalFunction";
import config from "../../../config/config";
import apiLavorazioni from "@/components/Presa/lavorazioni/js/lavorazioni";
let timeoutRicercaUser = null;

export default {
    name: 'Cliente',
    props: ['presa'],
    components: {
        AggiungiCliente,
        AggiungiBici
    },
    data() {
        return {
            searchUser: undefined,
            loadingSearchUser: false,
            listUsers: [],
            selectedUser: {},
            puntiTessera: 0,
            listBikes: [],
            selectedBike: {},
            aggiungiCliente: false,
            brands: [],
            spuntePrese: [],
            selectedSpuntePrese: [],
            lavaggio: false,
            primo_tagliando: false,
            garanzia: false,
            fornitore_garanzia: undefined,
            telefonare_quando_pronta: false,
            richieste: undefined,
            showAddBike: false
        }
    },
    watch: {
        async searchUser(search) {
            clearTimeout(timeoutRicercaUser);
            let those = this;
            timeoutRicercaUser = setTimeout(function () {
                those.startSearchUser(search);
            }, 500);
        },
        presa() {
            this.controlEditPresa();
        }
    },
    async created() {
        let token = await generalFunction.getToken();
        if (token) {
            let spuntePrese = await apiLavorazioni.getSpuntePresa(token);
            if (spuntePrese[1]) {
                for (let spunta of spuntePrese[1]) {
                    spunta.rName = 'Senza nome';
                    if (
                        spunta.it &&
                        spunta.it.nome
                    ) spunta.rName = spunta.it.nome;
                }
                this.spuntePrese = spuntePrese[1];
            }
        }

        await this.controlEditPresa();
        let brands = [] = await generalFunction.getBrands();
        if (brands[1]) {
            for (let brand of brands[1]) {
                brand.rName = 'Senza Nome';
                if (
                    brand &&
                    brand.it &&
                    brand.it.nome
                ) brand.rName = brand.it.nome;
            }
        }
        this.brands = brands[1];
    },
    methods: {
        async initUser(idUser) {
            let user = await apiUser.getUserClient(idUser);
            if (user) {
                this.selectedUser = { ...user };
                if (
                    user &&
                    user.dettagli_utente &&
                    user.dettagli_utente.tessera_punti &&
                    user.dettagli_utente.tessera_punti.punti
                ) this.puntiTessera = user.dettagli_utente.tessera_punti.punti;
            } else alert('Spiacenti si è verificato un errore');
        },
        async getUserBike() {
            this.listBikes = [];
            this.showAddBike = false;
            //let token = await generalFunction.getToken();
            if (this.selectedUser && this.selectedUser._id)
                await this.initUser(this.selectedUser._id);

            if (
                this.selectedUser &&
                this.selectedUser._id &&
                this.selectedUser.dettagli_utente &&
                this.selectedUser.dettagli_utente.bici &&
                this.selectedUser.dettagli_utente.bici.length
            ) {
                let listBikes = [];
                for (let rBike of [ ...this.selectedUser.dettagli_utente.bici ]) {
                    if(rBike['marca']) {
                        let rMarca = this.brands.find(brand => {
                            return brand._id === rBike['marca'];
                        });
                        if(rMarca)
                            rBike['rMarca'] = rMarca['rName'];
                    }
                    listBikes.push(rBike);
                }
                this.listBikes = listBikes;
            } else this.showAddBike = true;
        },
        async selectNewUser(idUser) {
            this.listBikes = [];
            this.selectedBike = {};
            this.aggiungiCliente = false;
            await this.initUser(idUser);
        },
        async controlEditPresa() {
            if (
                this.presa &&
                Object.entries(this.presa).length > 0
            ) {
                if (this.presa.richieste) this.richieste = this.presa.richieste;
                if (this.presa.lavaggio) this.lavaggio = this.presa.lavaggio;
                if (this.presa.primo_tagliando) this.primo_tagliando = this.presa.primo_tagliando;
                if (this.presa.garanzia) this.garanzia = this.presa.garanzia;
                if (this.presa.fornitore_garanzia) this.fornitore_garanzia = this.presa.fornitore_garanzia;
                if (this.presa.telefonare_quando_pronta) this.telefonare_quando_pronta = this.presa.telefonare_quando_pronta;

                if (this.presa.id_utente) {
                    await this.initUser(this.presa.id_utente);
                    await this.getUserBike();
                    if (
                        this.presa.id_bici &&
                        this.selectedUser &&
                        this.selectedUser.dettagli_utente &&
                        this.selectedUser.dettagli_utente.bici
                    ) {
                        let bike = this.selectedUser.dettagli_utente.bici.find(bike => {
                            return bike._id === this.presa.id_bici;
                        });
                        if (bike)
                            this.selectBike(bike)
                    }
                }

                if (this.presa.note_interne)
                    this.noteInterne = this.presa.note_interne;

                if (this.presa.richieste)
                    this.richieste = this.presa.richieste;

                let selectedSpuntePrese = [];
                if (
                    this.presa.spunte_presa &&
                    this.presa.spunte_presa.length > 0
                ) selectedSpuntePrese = this.presa.spunte_presa;
                this.selectedSpuntePrese = [ ...selectedSpuntePrese ];
            }
        },
        controlDate(dataAcquisto) {
            if (dataAcquisto) return momentLL(dataAcquisto);
            else return '';
        },
        editUser() {
            if (
                this.selectedUser &&
                this.selectedUser._id
            ) window.open(config.domainAdmin+'/admin/utenti?_id='+this.selectedUser._id);
            else alert('Seleziona prima un utente');
        },
        async startSearchUser(search) {
            if (search) {
                search = search.split(' ').join('|');
                if (this.loadingSearchUser) return;
                this.loadingSearchUser = true;
                let token = await generalFunction.getToken();
                if (
                    search &&
                    token
                ) {
                    let data = '';
                    data += "&token=" + token;
                    data += "&cerca=" + search;
                    let users = await apiUser.searchUsers(data);
                    if (
                        users &&
                        users.length
                    ) {
                        for (let user of users) {
                            user.rNameSearchUser = '';
                            if (user.numero) user.rNameSearchUser += user.numero + ' - ';
                            if (user.nome) user.rNameSearchUser += user.nome;
                            if (user.cognome) user.rNameSearchUser += ' ' + user.cognome;
                            if (user.email) user.rNameSearchUser += ' ' + user.email;
                            if (user.telefono) user.rNameSearchUser += ' (' + user.telefono + ')';
                        }
                        this.listUsers = users;
                    }
                }
                this.loadingSearchUser = false;
            }
        },
        selectBike(bike) {
            this.selectedBike = bike;
            if (
                this.listBikes &&
                this.listBikes.length
            ) for (let item of this.listBikes)
                item.active = String(item._id) === String(bike._id);
        },
        async deleteBike(bike) {
            let token = generalFunction.getToken();
            if (
                token &&
                bike &&
                bike._id &&
                this.selectedUser &&
                this.selectedUser._id
            ) {
                if (confirm("Sicuro di voler eliminare questa bici?")) {
                    let data = {
                        id_bici: bike._id,
                        id_utente: this.selectedUser._id
                    }
                    await apiUser.deleteUserBike(token, data);
                    await this.getUserBike();
                }
            } else alert('Spiacenti si è verificato un errore!');
        }
    }
}
</script>
